/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Copy This */

div.icon-text {
    display:flex; 
    flex-flow:column nowrap; 
    align-items: center;
}
div.icon-text > ion-icon {
    font-size: large;
    margin-bottom: 5px;
}
div.icon-text > span {
    font-size: xx-small;
}
ion-button.icon-text > span {
    font-size: xx-small;
    text-align: left;
    padding:5px;
}
//Add the TwoLine Button
ion-button.bigger {
    height: 42px;
}

//We need this because we can not set it on the component css
ion-header ion-searchbar input {
    box-shadow: none !important;
}
@media only screen and ( min-width: 700px ){
    //We need this to make our modal window bigger when we are opening them on our desktop :)
    //Properly list all of the ID that will be using big modals?
    ion-modal#ShowStatus div.modal-wrapper {
        width: 90%;
        height: 90%;
    }

    .hidden_big {
        display:none;
    }
}

//We use this to hide and show things based on our media
@media only screen and ( max-width:700px ){
    .hidden_small {
        display:none;
    }
}


//Utility Arrow
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.arrow.right {
    transform: rotate( -45deg );
    -webkit-transform: rorate( -45deg );
}
.arrow.left {
    transform: rotate( 135deg );
    -webkit-transform: rorate( 135deg );
}
.arrow.up {
    transform: rotate( -135deg );
    -webkit-transform: rorate( -135deg );
}
.arrow.down {
    transform: rotate( 45deg );
    -webkit-transform: rorate( 45deg );
}

//Login Modal CSS
.login-modal-penjual div.modal-wrappers {
    height:300px !important;
}
.login-modal-pembeli div.modal-wrappers {
    height:250px !important;
}


//Slide Show Bullet
/*
.swiper-pagination {
    position: fixed;
    bottom: -10px !important;
    padding-bottom: 3px;
}
*/

.item-borderless::part(native){
    border: none !important;
}

/*
:root{
    ion-item{
        font-size: 12px;
    }
    
    ion-tab-button{
        font-size: 16px;
    }
    
    ion-button{
       font-size: 16px;
    }
}
*/


//Always show scrollbar for our IMAGE Uploader
ion-slides.always-scrollbar{
    padding-bottom:15px;
}
ion-slides.always-scrollbar .swiper-scrollbar{
    opacity: 1 !important;
}


//Increase the Height of our Text Area
ion-textarea, textarea, .textarea-wrapper {
    height:250px !important;
}

.alert-wrapper {
    width: 80% !important;
    max-width: 80% !important;
}